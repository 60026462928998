<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col
            cols='12'
            md='4'
            class='mb-md-0 mb-2'
          >
            <b-form-group
              :label="$t('labels.name')"
              label-for='name'
            >
              <b-form-input
                id='name'
                :value='searchFields.name'
                @input="inputChange($event, 'name')"
              />
            </b-form-group>
          </b-col>
          <!--          <b-col-->
          <!--            cols="12"-->
          <!--            md="4"-->
          <!--            class="mb-md-0 mb-2"-->
          <!--          >-->
          <!--            <b-form-group-->
          <!--              :label="$t('labels.email')"-->
          <!--              label-for="email"-->
          <!--            >-->
          <!--              <b-form-input-->
          <!--                id="email"-->
          <!--                :value="searchFields.email"-->
          <!--                @input="inputChange($event, 'email')"-->
          <!--              />-->
          <!--            </b-form-group>-->
          <!--          </b-col>-->
          <b-col
            cols='12'
            md='4'
            class='mb-md-0 mb-2'
          >
            <b-form-group
              :label="$t('labels.phone')"
              label-for='phone'
            >
              <b-form-input
                id='phone'
                :value='searchFields.phone'
                @input="inputChange($event, 'phone')"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols='12'
            md='4'
            class='mb-md-0 mb-2'
          >
            <label>{{ $t('labels.status') }}</label>
            <v-select
              v-model='searchFields.status'
              :dir='$store.state.appConfig.layout.direction'
              :options='statusList'
              :reduce='item => item.value'
              label='label'
              class='w-100'
            />
          </b-col>
          <b-col
            cols='12'
            md='4'
            class='mb-md-0 mb-2'
          >
            <DatePicker
              :label="$t('labels.created_on')"
              :reset='true'
              v-model='created_at'
            />
          </b-col>

        </b-row>
      </b-card-body>
    </b-card>
    <TableIndex
      :resource-name='resourceName'
      :singular-name='singularName'
      :filter-fields='searchFields'
      :edit-route="''"
      :can-change-status='false'
      :status-object='selectedItemToChangeStatus'
      :have-dropdown-actions='checkPermission("can_change_status")'
      :permissionKey='"conflict"'

    >
      <template
        v-slot:custom_actions='{ item, generalActions }'
      >
        <b-link
          v-if='item.actions.can_view'
          v-b-tooltip.hover.v-primary
          :title="$t('Details')"
          class='ml-1'
          :to="{ name: 'conflict-details', params: { resourceId: item.id } }"
        >
          <feather-icon
            icon='EyeIcon'
            size='16'
            class='align-middle text-body'
          />
        </b-link>
      </template>
      <template v-slot:dropdown_actions='{ item, generalActions }' v-if='checkPermission("can_change_status")'>
        <b-dropdown-item
          v-if='(item.actions && item.actions.can_change_status)'
          @click='showModal(item,item.status)'
        >
          <feather-icon
            icon='FilterIcon'
            class='mr-50'
          />
          <span>{{ $t('status') }}</span>
        </b-dropdown-item>
      </template>
    </TableIndex>
    <b-modal
      id='modal-status'
      ref='modal-status'
      cancel-variant='outline-secondary'
      :ok-title="$t('yes')"
      :cancel-title="$t('no')"
      centered
      :title="$t('change_status')"
      hide-footer
      :cancel-disabled='false'
    >
      <validation-observer
        ref='form'
        v-slot='{invalid}'
      >
        <b-form
          novalidate
          @submit.prevent='changeStatus'
        >
          <validation-provider
            v-slot='{ errors }'
            vid='status'
            :name="$t('status')"
            rules='required'
          >
            <b-form-group
              :label="$t('status')"
            >
              <v-select
                v-model='form.status'
                :dir='$store.state.appConfig.layout.direction'
                label='label'
                :options='statusList'
                :reduce='item => item.value'
                :placeholder="$t('select')"
              />
<!--              <small class='text-danger'>{{ errors[0] }}</small>-->
            </b-form-group>
          </validation-provider>
          <div class='d-flex'>
            <LoadingButton />
            <b-button
              variant='outline-secondary'
              @click='hideModal'
            >
              {{ $t('close') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'
import { getPermissions } from '@core/utils/utils'
import { websiteMainUrl } from '@/services/url.service'

export default {
  mixins: [listMixin],
  data() {
    return {
      resourceName: 'conflict',
      singularName: 'conflict',
      permissions: [],
      user: null,
      searchFields: {
        name: null,
        // email: null,
        phone: null,
        created_at: null,

      },
      created_at: null,

      form: {
        status: null,
      },
      selectedItemToChangeStatus: { status: null, id: null },
      item_id: null,
      statusList: [
        {
          label: this.$t('statuses.pending'),
          value: 'pending',
        },
        {
          label: this.$t('statuses.accepted'),
          value: 'accepted',
        },
        {
          label: this.$t('statuses.replied'),
          value: 'replied',
        },
        {
          label: this.$t('statuses.closed'),
          value: 'closed',
        },
      ],
    }
  },
  created() {
    this.permissions = getPermissions()
    this.user = JSON.parse(localStorage.getItem('userData'))

  },

  watch: {
    'created_at': {
      handler(val) {
        if (this.searchFields['created_at'] == null && (val == '' || val == null)) {
          return true
        }

        this.searchFields['created_at'] = val
      },
    },
  },
  methods: {
    checkPermission(permission = 'can_create') {

      if (this.user['role_id'] === 1 || this.user['role_id'] === 2) {
        return true
      }

      // if (this.generalActions?.can_create != null) {
      //   return this.generalActions?.can_create
      // }

      if (this.permissionKey) {
        return (this.permissions[this.permissionKey] ?? [])[permission] ?? false
      }

      return false
    },

    showModal(item, status) {
      this.form.status = this.$t(`statuses.${status}`)
      this.$refs['modal-status'].show()
      this.item_id = item.id
    },
    hideModal() {
      this.$refs['modal-status'].hide()
      setTimeout(() => {
        this.item_id = null
        this.form.status = null
      }, 500)
    },
    // eslint-disable-next-line func-names
    inputChange: _.debounce(function(e, field) {
      this.searchFields[field] = e
    }, 500),
    changeStatus() {
      this.$refs.form.validate()
        .then(result => {
          if (result) {
            this.axios.patch(`/conflict/${this.item_id}/change-status`, this.form)
              .then(response => {

                this.selectedItemToChangeStatus = {
                  status: this.form.status,
                  id: this.item_id,
                  status_label_color: response.data.data.status_label_color,
                }
                this.hideModal()
                setTimeout(() => {
                  this.$bvToast.toast(response.data.message, {
                    title: this.$t('Success'),
                    variant: 'success',
                    solid: true,
                    autoHideDelay: 5000,
                    appendToast: true,
                  }, 500)
                  this.form.status = null
                })
              })
          }
        })
    },
  },
  // eslint-disable-next-line vue/order-in-components
  // computed: {
  //   statuses() {
  //     return [
  //       {
  //         id: 'pending',
  //         name: this.$t('statuses.pending'),
  //       },
  //       {
  //         id: 'accepted',
  //         name: this.$t('statuses.accepted'),
  //       },
  //       {
  //         id: 'canceled',
  //         name: this.$t('statuses.canceled'),
  //       },
  //       {
  //         id: 'closed',
  //         name: this.$t('statuses.closed'),
  //       },
  //     ]
  //   },
  // },
}
</script>
